angular.module('app')
    .controller('bookingCancelModalController',
    ["$scope", "$log", "$uibModalInstance", "message", "cancellationReasons", function ($scope, $log, $uibModalInstance, message, cancellationReasons) {
        var vm = this;
        vm.message = message;
        vm.selectedReason = undefined;
        vm.cancellationReasons = cancellationReasons;
        vm.cancelBooking = cancelBooking;

        function cancelBooking() {
            $uibModalInstance.close(vm.selectedReason);
        }

    }]);